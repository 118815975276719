.border-placeholder {
  
  &.border-bottom {
    border-color: #9FBBCC !important;
  }
}

@media print {
  @page {
    size: 1390px 1800px;
  }
}
