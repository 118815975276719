@import '../../../scss/config';

.search-filters-container {

  .label-text {
    width: rem(140px);
    max-width: rem(140px);
  }

  .form-control {
    width: rem(210px);
    max-width: rem(210px);
  }

  .view-more-button {
    border: none;
    background: transparent;
    color: $placeholder;
    font-size: rem(20px);
    text-align: right;
  }

  div[class*='-container'] {
    width: rem(210px);
    max-width: rem(210px);
    padding: 0;
  }

  .currency-input {
    width: rem(210px);
    max-width: rem(210px);
    padding: 0;
  }

  .search-format {
    border: none;
    background: transparent;
    background-color: #FFFFFF !important;
  }

  .accordion-button:after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23697588'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  }

  .search-border {
    border: none !important;
  }

  .housing-options-search {

    .search-icon {
      color: $placeholder;
    }

    .form-control {
      font-size: rem(14px);
    }

    ::placeholder {
      text-align: start;
    }
  }
}
