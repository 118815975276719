@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;1,400;1,600&display=swap');

$font-family-sans-serif: 'Open Sans', sans-serif;

$very_large: 2.5rem; // 40px
$XXX_large: 1.875rem; // 30px
$XX_large: 1.5625rem; // 25px
$extra_large: 1.25rem; // 20px
$large: 1rem; // 16px
$regular: .875rem; // 14px
$small: .75rem; // 12px
$extra_small: .625rem; // 10px
