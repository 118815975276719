.reverse {
  display: flex;
  flex-direction: column-reverse;
}

.desktop {
  position: sticky;
  top: 40px;
  box-shadow: 2px 4px 24px;
}
