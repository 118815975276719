@import '../../scss/config';

.main-search {
  height: rem(140px);
  min-height: rem(140px);
  max-height: rem(140px);
}

.modify-search {
  height: rem(75px);
  min-height: rem(75px);
  max-height: rem(75px);

  .form-control {
    font-size: rem(14px);
  }
}

.search-heading {
  width: 100%;
  border-radius: rem(10px);
  background-color: $white;
  box-shadow: 2px 4px 24px 0 rgba(0, 0, 0, .3);

  .main-filter-size {
    height: rem(80px);
    min-height: rem(80px);
    max-height: rem(80px);
    margin: rem(10px);
    margin-top: rem(35px);
  }

  .modify-filter-size {
    height: rem(60px);
    min-height: rem(60px);
    max-height: rem(60px);
    margin: rem(5px);
  }

  .filter {
    border-width: rem(1px);
    border-style: none solid none none;
    border-color: $inputlines;
    color: $text;
    font-weight: 300;
    text-align: center;
  }
  // commented out until location is added back in

  .distance {
    // max-width: rem(200px);
  }

  .gender {
    // max-width: rem(300px);
  }

  .city {
    // max-width: rem(400px);
  }

  .mainSearchCity {

    ::placeholder {
      color: $primary;
      font-weight: 600;
    }
  }

  .location {
    margin-top: 0;
    border-width: rem(1px);
    border-style: none none solid;
    border-color: $inputlines;
    border-radius: 0;
    text-align: center;
  }

  .search-button {
    border: 1px solid $primary;
    border-radius: 10px;
    background-color: $primary;
    color: $white;
  }

  .filter-label {
    overflow: hidden;
    margin-bottom: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
