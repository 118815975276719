@import '../../../scss/config';

.rh-carousel-item {
  position: relative;
  width: rem(378px);
  min-width: 0;
  max-width: rem(378px);
  height: rem(400px);
  margin: 0 10px;
  border-radius: rem(10px);
  touch-action: none;
}

.carousel-image {
  touch-action: none;
  cursor: pointer;
}

.carousel-gradient {
  position: absolute;
  bottom: 0;
  width: rem(358px);
  max-width: 100%;
  height: rem(190px);
  border-radius: 0 0 10px 10px;
  background: linear-gradient(180deg, rgba(0, 66, 102, 0) 0%, #004266 100%);
  touch-action: none;
}

.carousel-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20%;
  padding: 78px 10px 0;
  text-align: center;
  touch-action: none;
}

.carousel-text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50%;
  background: linear-gradient(180deg, rgba(0, 66, 102, 0) 0%, #004266 50%);
  text-align: center;
  touch-action: none;
}

.carousel-text div {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
