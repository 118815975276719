@import '../../scss/config';

.avg-rent-container {
  position: relative;
  min-width: 8rem;
  margin-top: rem(10px);
  font-size: rem(14px);

  .rent-button {
    border: none;
    background: transparent;
    text-decoration: none;

    .icon-color {
      color: $placeholder;
    }
  }

  .title {
    font-weight: 600;
  }

  .rent-card {
    position: absolute;
    z-index: 1;
    width: 225px;
    height: 260px;
    margin-top: rem(20px);
    padding: rem(20px);
    border-radius: 4px;
    background-color: $white;
    box-shadow: 0 0 12px 0 rgba(0,0,0,.3);
  }
}
