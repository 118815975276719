.circleButton {
  width: 48px;
  height: 48px;
  padding: 10px 1px 1px 11px;
  border: 0;
  border-radius: 50%;
  background: #FFFFFF;
  box-shadow: 1px 1px 8px 0 rgba(0,0,0,.3);
  text-align: center;
}
