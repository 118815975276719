@import '../../scss/config';

.content-type {
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 2px;

  .icon {
    width: 100%;
    height: 100%;
    color: $white;
  }
}
