@import '../../scss/config';

.pagination {
  font-weight: 600;
  font-size: 14px;

  .pagination-button {
    background-color: $pagebg;
    color: $secondary;
    font-weight: 600;
    font-size: 16px;
  }

  button:disabled {
    color: $placeholder;
  }
}
