.order-product-title {
  display: -webkit-box;
  overflow: hidden;
  max-height: 68px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.dh-col {
  min-width: 100px;
}

.qty-col {
  min-width: 100px;
}

.delete-col {
  min-width: 30px;
}
