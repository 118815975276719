@import '../../scss/config';

.list-house-container {
  margin-bottom: 20px;
  @media only screen and (min-width: 576px) {
    margin: 0 20px 20px;
  }
  border-radius: 4px;
  background-color: $white;
  box-shadow: 1px 2px 8px 0 rgba(0,0,0,.3);
  font-size: 14px;
  @media only screen and (min-width: 576px) {
    height: 277px;
  }

  .house-img-carousel {
    width: 100%;
    @media only screen and (min-width: 576px) {
      width: 400px;
      max-width: 400px;
      height: 277px;
    }
  }

  img {
    width: 100%;
    @media only screen and (min-width: 576px) {
      width: 400px;
      max-width: 400px;
      border-radius: 4px 0 0 4px;
    }
    height: 277px;
    border-radius: 4px 4px 0 0;
  }

  .as_of {
    padding-left: 10px;
    color: $darkgray;
    font-size: 10px;
  }

  .view-more-button {
    border: none;
    background: transparent;
    color: $secondary;
    font-size: rem(14px);
    text-decoration: underline;
  }

  .no-underline {

    a:link {
      text-decoration: none;
    }

    a:visited {
      text-decoration: none;
    }

    a:hover {
      color: $black;
      text-decoration: none;
    }

    a:active {
      text-decoration: none;
    }
  }

  .regular-link {
    color: $textlink;
    text-decoration: underline !important;
  }
}
