.numberCircle {
  width: 36px;
  height: 36px;
  padding: 6px 7px 7px 8px;
  border-radius: 50%;
  background: #004266;
  color: #B7E3FF;
  text-align: center;
}

.linkSecImage {
  width: auto;
  max-width: 300px;
  height: auto;
  max-height: 200px;
}
