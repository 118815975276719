@import '../../scss/config';

.sort-label {
  width: 50px;
  max-width: 50px;
  padding-top: 8px;
}

.rec-select {
  width: 165px;
  max-width: 165px;
  padding: 0;
  font-size: 14px;
}

.search-bar {
  height: 38px;
}

.label-padding {
  padding-top: 8px;
}

.sort {
  width: 60px;
  text-align: center;
}

.complete-my-order {
  font-size: 14px;
}

.results-width {
  width: 80px !important;
  max-width: 80px !important;
}

.sticky-button {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background-color: $white;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, .3);
}
