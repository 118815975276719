@import '../../scss/config';

.product-qty {
  width: 100%;
  max-width: 100%;
  height: 37px;

  .qty-input {
    width: 80px;
    max-width: 80px;
    font-size: 14px;
  }
}

.clear-selection {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  overflow: hidden;
  color: $textlink;
  font-size: 14px;
}

.clear-selection:after {
  content: '\00A0';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  letter-spacing: 1000px;
  text-decoration: underline;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
