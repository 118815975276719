@import '../../scss/config';

.mobile-main-search {
  margin: 20px;

  .filter {
    height: 80px;
    min-height: 80px;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid $inputlines;
    border-radius: 10px;
    color: $text;
    font-weight: 300;
    font-size: 1rem;
    text-align: center;
  }

  .location {
    width: 80%;
    margin-top: 5px;
    border-width: 1px;
    border-style: none none solid;
    border-color: $inputlines;
    border-radius: 0;
    text-align: center;
  }

  .filter-label {
    overflow: hidden;
    margin-bottom: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
